import ALPHA from '../images/alpha.svg'
import BTSE from '../images/btse.svg'
import DAI from '../images/dai.svg'
import ETH from '../images/eth.svg'
import PRT from '../images/prt.svg'
import SFIBlue from '../images/sfiblue.svg'
import SFI from '../images/sfired.svg'
import unknown from '../images/missing-token-icon.svg'

const icons: Record<string, string> = {
  ALPHA,
  BTSE,
  DAI,
  ETH,
  WETH: ETH,
  PRT,
  SFI,
  'SFI-Blue': SFIBlue,
  unknown,
}

export function getTokenIcon(symbol: string) {
  const icon = icons[symbol]
  if (!icon) return icons.unknown
  return icon
}
