export function UUID() {
  const arr = []; for (let i = 0; i < 256; i++) { arr[i] = (i < 16 ? '0' : '') + (i).toString(16) }
  const d0 = Math.random() * 0xffffffff | 0
  const d1 = Math.random() * 0xffffffff | 0
  const d2 = Math.random() * 0xffffffff | 0
  const d3 = Math.random() * 0xffffffff | 0
  return `${arr[d0 & 0xff] + arr[d0 >> 8 & 0xff] + arr[d0 >> 16 & 0xff] + arr[d0 >> 24 & 0xff]}-${
    arr[d1 & 0xff]}${arr[d1 >> 8 & 0xff]}-${arr[d1 >> 16 & 0x0f | 0x40]}${arr[d1 >> 24 & 0xff]}-${
    arr[d2 & 0x3f | 0x80]}${arr[d2 >> 8 & 0xff]}-${arr[d2 >> 16 & 0xff]}${arr[d2 >> 24 & 0xff]
  }${arr[d3 & 0xff]}${arr[d3 >> 8 & 0xff]}${arr[d3 >> 16 & 0xff]}${arr[d3 >> 24 & 0xff]}`
}
