import {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { URL } from 'url-shim'
import { API_HOSTNAME } from '../settings'
import { useWeb3ReactPlus } from '../Web3ReactPlus/Web3ReactPlusProvider'
import { useBlockNumber } from '../Web3ReactPlus/hooks/useBlockNumber'
import { AppStateActionName, useAppStateReducer } from '../AppState'

const apiNetworkNames: { [chainId: number]: string } = {
  1: 'mainnet',
  4: 'rinkeby',
}

// TODO: Debounce via https://github.com/lodash/lodash/issues/4400#issuecomment-905035552 ?
const fetchFromApi = async (chainId: number | undefined, path: string) => {
  let data
  let error

  if (chainId) {
    const networkName = apiNetworkNames[chainId] || 'unsupported'
    const formattedHostname = API_HOSTNAME.replace('{network}', networkName)
    try {
      const response = await fetch(new URL(`/v2/${path}`, formattedHostname).href)
      data = await response.json()
    } catch (e) {
      console.error(e)
      error = e
    }
  }

  return { data, error, isFetching: false }
}

export function useApiData() {
  const { chainId } = useWeb3ReactPlus()
  const blockNumber = useBlockNumber()
  const [appState, dispatch] = useAppStateReducer()

  //
  const attemptedLoad = chainId
      && (!!appState?.[chainId]?.api?.pools?.data || !!appState?.[chainId]?.api?.pools?.error)
      && (!!appState?.[chainId]?.api?.['protocol-info']?.data || !!appState?.[chainId]?.api?.['protocol-info']?.error)

  // Load protocol info once per chain ID
  useEffect(() => {
    if (!chainId) return
    fetchFromApi(chainId, 'protocol-info')
      .then((result) => {
        dispatch({
          type: AppStateActionName.LoadProtocolInfo,
          payload: { chainId, ...result },
        })
      })
  }, [chainId])

  // Update pools on every new block
  useEffect(() => {
    if (chainId && !appState[chainId]?.api?.pools?.isFetching) {
      dispatch({
        type: AppStateActionName.FetchingApiData,
        payload: { chainId, path: 'pools' },
      })
      fetchFromApi(chainId, 'pools')
        .then((result) => {
          dispatch({
            type: AppStateActionName.UpdatePoolsData,
            payload: { chainId, ...result },
          })
        })
    }
  }, [chainId, blockNumber])

  return attemptedLoad
}
