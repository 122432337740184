import { useAppStateReducer } from '../AppState'
import { useWeb3ReactPlus } from '../Web3ReactPlus/Web3ReactPlusProvider'

const noPools: any[] = []

export function useDisplayedPools() {
  const { chainId } = useWeb3ReactPlus()
  const [appState] = useAppStateReducer()
  const displayedPools = appState[chainId as number]?.displayedPools
  if (!displayedPools) return noPools
  return displayedPools
}
