import { Contract } from 'ethers'

export function getFunctionSignature(artifact: any, functionName: string) {
  // Use temp address with Contract constructor to easily parse ABI
  const contract = new Contract('0x0000000000000000000000000000000000000000', artifact.abi)
  const contractFunctionNames = Object.keys(contract.functions)
  if (functionName.indexOf('(') !== -1) {
    if (contractFunctionNames.indexOf(functionName) !== -1) {
      throw Error(
        `Function with signature '${functionName}' not found in loaded ABI for contract '${artifact.contractName}'`,
      )
    }
    return functionName
  }
  const matchingSignatures = contractFunctionNames.filter((abiFunctionName) => abiFunctionName.startsWith(`${functionName}(`))
  if (matchingSignatures.length > 1) {
    throw Error(
      `Function with name '${functionName}' has more than one signature; `
      + `please pass one of these signatures directly: ${
        matchingSignatures.map((signature) => `'${signature}'`).join(', ')}`,
    )
  }
  if (!matchingSignatures[0]) {
    throw Error(`No function with name '${functionName}' found on contract '${artifact.contractName}'`)
  }
  return matchingSignatures[0]
}
