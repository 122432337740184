import styled, { css } from 'styled-components'
import { ReactNode } from 'react'
import noticeIcon from '../../images/notice.svg'

interface NoticeProps {
  noticeText: ReactNode
  mobileOnly?: boolean
}

export function Notice({ noticeText, mobileOnly }: NoticeProps) {
  return (
    <OuterWrap mobileOnly={mobileOnly}>
      <InnerWrap>
        <IconWrap><img width={20} alt="Notice" src={noticeIcon} /></IconWrap>
        <NoticeText>
          {noticeText}
        </NoticeText>
      </InnerWrap>
    </OuterWrap>
  )
}

const OuterWrap = styled.div<{ mobileOnly?: boolean }>`
  margin: 5px auto -20px;

  ${({ mobileOnly }) => (mobileOnly ? css`
      @media (min-width: 801px) {
        display: none;
      }
    ` : '')}
`

const InnerWrap = styled.div`
  text-align: left;
  color: #003d66;
  padding: 10px 8px 4px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  
  a {
    color: #003d66 !important;
    font-weight: bold;
  }
`

const IconWrap = styled.span`
  padding: 0 10px 0 6px;
`

const NoticeText = styled.div``
