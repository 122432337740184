import { Web3ReactPlusProvider } from './Web3ReactPlus/Web3ReactPlusProvider'
import { AppStateProvider } from './AppState'
import { App } from './App'
import { DEFAULT_CHAIN_ID } from './settings'

export default function Root() {
  const defaultChainId = parseInt(DEFAULT_CHAIN_ID as string)

  return (
    <Web3ReactPlusProvider defaultChainId={defaultChainId}>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </Web3ReactPlusProvider>
  )
}
