import Modal from 'react-modal'
import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import { formatUnits } from 'ethers/lib.esm/utils'
import { BigNumber } from 'ethers'
import closeIcon from '../../../images/close-x.svg'
import { useTokenIcons } from '../../../hooks/useTokenIcons'
import { Image } from '../shared/Image'
import { usePendingUsd } from '../../../hooks/usePendingUsd'
import { usePendingSfi } from '../../../hooks/usePendingSfi'
import { trimDecimalStringToPrecision } from '../../../utils/trimDecimalStringToPrecision'
import { TransactionStatus, useTrackedTransaction } from '../../../Web3ReactPlus/hooks/useTrackedTransaction'
import { useSaffronStakingProtocolInfo } from '../../../hooks/useSaffronStakingProtocolInfo'
import SaffronStakingV2 from '../../../artifacts/contracts/SaffronStakingV2.sol/SaffronStakingV2.json'
import { AnimatedCheckmark } from '../shared/AnimatedCheckmark'
import { LoadingSpinner } from '../shared/LoadingSpinner'

interface EarningsProps {
  isOpen: boolean
  onRequestClose: (...args: any[]) => any
  poolInfo: any
}

const TRANSITION_MS = 200

export function EarningsModal(props: EarningsProps) {
  const { isOpen, onRequestClose, poolInfo } = props

  const [HarvestButtonContentDiv, setHarvestButtonContentDiv] = useState(<HarvestButtonContent />)
  const [harvestErrorText, setHarvestErrorText] = useState<string | undefined>()
  const [harvestLoading, setHarvestLoading] = useState(false)
  const [harvestComplete, setHarvestComplete] = useState(false)

  const [token1Svg, token2Svg] = useTokenIcons(poolInfo)
  const [pendingUsd] = usePendingUsd(poolInfo.id)
  const [pendingSfi] = usePendingSfi(poolInfo.id)
  const { saffronStakingAddress } = useSaffronStakingProtocolInfo()

  const [harvestTxState,
requestHarvest,
canHarvest,
resetHarvest] = useTrackedTransaction({
    contractAddress: saffronStakingAddress,
    artifact: SaffronStakingV2,
    functionName: 'withdraw',
    allowRequestOnConfirmed: false,
  })

  function handleHarvest() {
    requestHarvest({
      args: [poolInfo.id, BigNumber.from(0)],
    })
  }

  const defaultButtonContent = (
    <HarvestButtonContent>
      Harvest
      {' '}
      {formatUnits(pendingSfi || BigNumber.from(0), 18)}
      {' '}
      SFI
    </HarvestButtonContent>
  )

  useEffect(() => {
    if (!isOpen && harvestTxState.status === TransactionStatus.Confirmed) {
      resetHarvest()
    }
  }, [isOpen])

  useEffect(() => {
    switch (harvestTxState.status) {
      case TransactionStatus.Init:
        setHarvestButtonContentDiv(defaultButtonContent)
        break
      case TransactionStatus.Pending:
        setHarvestComplete(false)
        setHarvestLoading(true)
        setHarvestButtonContentDiv(
          <HarvestButtonContent>
            <LoadingSpinner />
            {' '}
            Harvesting...
          </HarvestButtonContent>,
        )
        break
      case TransactionStatus.Confirmed:
        setHarvestComplete(true)
        setHarvestLoading(false)
        setHarvestButtonContentDiv(
          <HarvestButtonContent>
            <AnimatedCheckmark />
            {' '}
            Harvest confirmed!
          </HarvestButtonContent>,
        )
        break
      case TransactionStatus.Failed:
        setHarvestLoading(false)
        setHarvestButtonContentDiv(defaultButtonContent)
        setHarvestErrorText(harvestTxState.error.message)
        break
    }
  }, [pendingSfi, harvestTxState.status])

  return (
    <Modal
      closeTimeoutMS={TRANSITION_MS + 17}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      contentElement={(props, children) => <ModalElement {...props}>{children}</ModalElement>}
      overlayElement={(props, contentElement) => <OverlayElement {...props}>{contentElement}</OverlayElement>}
    >
      <Title>
        <Icon>
          <Image width="24px" height="24px" src={token1Svg} />
        </Icon>
        {poolInfo.stakedToken?.underlyingToken1
          ? (
            <Icon overlap>
              <Image width="24px" height="24px" src={token2Svg} />
            </Icon>
          )
          : null}
        <PairName>{poolInfo.pairName}</PairName>
      </Title>
      <Close onClick={onRequestClose}><img alt="Close" src={closeIcon} /></Close>
      <Subtitle>
        Rewards are earned from the
        {' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://medium.com/saffron-finance/saffron-staking-v2-is-live-on-ethereum-mainnet-7d8c65c34972"
        >
          SFI Emissions Program
        </a>
        .
      </Subtitle>
      <EarningsDisplay>
        <EarningsItem>
          <EarningsTitle>Earnings in USD</EarningsTitle>
          <EarningsValue>{pendingUsd || '$0'}</EarningsValue>
        </EarningsItem>
        <Equals>=</Equals>
        <EarningsItem>
          <EarningsTitle>Earnings in SFI</EarningsTitle>
          <EarningsValue>
            {trimDecimalStringToPrecision(
              formatUnits(pendingSfi || BigNumber.from(0), 18),
              4,
            )}
            {' '}
            SFI
          </EarningsValue>
        </EarningsItem>
      </EarningsDisplay>
      <HarvestButton
        disabled={!canHarvest}
        loading={harvestLoading}
        complete={harvestComplete}
        onClick={handleHarvest}
      >
        {HarvestButtonContentDiv}
      </HarvestButton>
      <Footnote>
        Harvesting also automatically occurs when withdrawing.
      </Footnote>
    </Modal>
  )
}

const ModalElement = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 440px;
  background: #fff;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  text-align: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #0f1621;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`

const Close = styled.div`
  position: fixed;
  right: 1px;
  top: 2px;
  padding: 10px 15px;
  cursor: pointer;
`

const Icon = styled.div<{ overlap?: boolean }>`
  margin-left: ${(props) => (props.overlap ? '-5px' : '0')};
  display: inline;
  line-height: 0;
`

const PairName = styled.span`
  display: inline;
  vertical-align: top;
  margin-left: 10px;
`

const Subtitle = styled.div`
  font-size: 15px;
  text-align: left;
  color: #0f1621;
`

const EarningsDisplay = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  justify-content: center;
  gap: 30px;
`

const EarningsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const EarningsTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: rgb(84,84,98);

`

const EarningsValue = styled.div`
  color: rgb(16,22,32);
  font-size: 20px;
  font-weight: 600;
`

const Equals = styled.div`
  border: 1px solid #ddd5d5;
  width: 26px;
  height: 26px;
  border-radius: 16px;
  text-align: center;
  line-height: 0.9;
  background: #f8f7f6;
  color: #666666;
  font-size: 26px;
  font-weight: bold;
  cursor: default;
  user-select: none;
`

const HarvestButton = styled.button<{ disabled?: boolean, loading?: boolean, complete?: boolean }>`
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: not-allowed;
  padding: 0 16px;
  
  ${({ disabled }) => {
    if (!disabled) {
      return css`
          cursor: pointer;
          color: white;
          &:hover ::before {
            opacity: 1;
          }
          &:active {
            transform: scale(0.97);
          }
        `
    }
    return ''
  }}

  ${({ loading }) => {
    if (loading) {
      return css`
        color: white !important;
      `
    }
    return ''
  }}
  
  ${({ complete }) => {
    if (complete) {
      return css`
        color: white !important;
      `
    }
    return ''
  }}

  border-radius: 8px;
  background: linear-gradient(to right, #c44536, #c48c36);
  position: relative;
  z-index: 1;
  transition: transform 0.05s;

  ::before {
    border-radius: 8px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left, #c44536, #c48c36);;
    z-index: -1;
    transition: opacity 0.15s linear;
    opacity: 0;
  }
`

const HarvestButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Footnote = styled.div`
  margin-top: 17px;
  font-size: 13px;
  color: rgb(84,84,98);
`

const OverlayElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EEF0FDAF;
  z-index: 10;
  backdrop-filter: blur(2px);
  transition: opacity ${TRANSITION_MS}ms ease-out;

  &.ReactModal__Overlay {
    opacity: 0;
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`
