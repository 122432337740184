import { BigNumber } from '@ethersproject/bignumber'
import { UseContractCallValueReturnValue, useContractCallValue } from '../Web3ReactPlus/hooks/useContractCallValue'
import { useSaffronStakingProtocolInfo } from './useSaffronStakingProtocolInfo'
import SaffronStakingV2 from '../artifacts/contracts/SaffronStakingV2.sol/SaffronStakingV2.json'
import { useWeb3ReactPlus } from '../Web3ReactPlus/Web3ReactPlusProvider'

export function useAmountStaked(poolId: number, autoUpdate = true, updateEveryNBlocks = 1): UseContractCallValueReturnValue<BigNumber> {
  const { activeAddress } = useWeb3ReactPlus()
  const { saffronStakingAddress } = useSaffronStakingProtocolInfo()
  const [data,
isFetching,
forceUpdate,
fetchError,
lastFetchError] = useContractCallValue({
    contractAddress: saffronStakingAddress ?? '',
    artifact: SaffronStakingV2,
    functionName: 'userInfo',
    args: [poolId, activeAddress],
    autoUpdate,
    updateEveryNBlocks,
  })
  return [data?.amount, isFetching, forceUpdate, fetchError, lastFetchError]
}
