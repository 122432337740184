import { useEffect, useState } from 'react'
import { Contract, ContractReceipt, ContractTransaction } from 'ethers'
import { useChainStateReducer } from '../ChainStateProvider'
import { useWeb3ReactPlus } from '../Web3ReactPlusProvider'
import { getFunctionSignature } from './shared/getFunctionSignature'
import { UUID } from '../../utils/UUID'

export interface UseTrackedTransactionOptions {
  contractAddress?: string
  artifact?: any
  functionName: string
  waitConfirmations?: number
  allowRequestOnConfirmed?: boolean
  allowRequestOnCancelledRequest?: boolean
  allowRequestOnFailedRequest?: boolean
  allowRequestOnFailed?: boolean
  resetOnCancelledRequest?: boolean
}

export type UseTrackedTransactionReturnValue = [
  txState: TrackedTransaction,
  requestTx: (options: RequestTransactionOptions) => Promise<boolean>,
  canRequest: boolean,
  reset: () => void,
]

export interface RequestTransactionOptions {
  args?: any[]
  txOptions?: any
  props?: any
}

export enum TransactionStatus {
  Init = 'Init',
  Requesting = 'Requesting',
  CancelledRequest = 'CancelledRequest',
  FailedRequest = 'FailedRequest',
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Failed = 'Failed',
}

interface InitTransaction {
  status: TransactionStatus.Init
  props: any
}

interface RequestingTransaction {
  status: TransactionStatus.Requesting
  props: any
  fromAddress: string
  localTxId: string
  contractAddress: string
  contractName: string
  functionName: string
  functionSignature: string
  args: any[]
}

interface CancelledRequestTransaction {
  status: TransactionStatus.CancelledRequest
  props: any
  fromAddress: string
  localTxId: string
  contractAddress: string
  contractName: string
  functionName: string
  functionSignature: string
  args: any[]
}

interface FailedRequestTransaction {
  status: TransactionStatus.FailedRequest
  props: any
  fromAddress: string
  localTxId: string
  contractAddress: string
  contractName: string
  functionName: string
  functionSignature: string
  args: any[]
  error: Error
}

interface PendingTransaction {
  status: TransactionStatus.Pending
  props: any
  fromAddress: string
  localTxId: string
  contractAddress: string
  contractName: string
  functionName: string
  functionSignature: string
  args: any[]
  timeSubmitted: Date
  tx: ContractTransaction
}

interface ConfirmedTransaction {
  status: TransactionStatus.Confirmed
  props: any
  fromAddress: string
  localTxId: string
  contractAddress: string
  contractName: string
  functionName: string
  functionSignature: string
  args: any[]
  timeSubmitted: Date
  tx: ContractTransaction
  txReceipt: ContractReceipt
}

interface FailedTransaction {
  status: TransactionStatus.Failed
  props: any
  fromAddress: string
  localTxId: string
  contractAddress: string
  contractName: string
  functionName: string
  functionSignature: string
  args: any[]
  timeSubmitted: Date
  tx: ContractTransaction
  txReceipt?: ContractReceipt
  error: Error
}

export type TrackedTransaction =
  | InitTransaction
  | RequestingTransaction
  | CancelledRequestTransaction
  | FailedRequestTransaction
  | PendingTransaction
  | ConfirmedTransaction
  | FailedTransaction

function getDefaultTrackedTransaction(): InitTransaction {
  return {
    status: TransactionStatus.Init,
    props: {},
  }
}

export function useTrackedTransaction({
  contractAddress,
  artifact,
  functionName,
  waitConfirmations,
  allowRequestOnConfirmed = true,
  allowRequestOnCancelledRequest = true,
  allowRequestOnFailedRequest = true,
  allowRequestOnFailed = true,
  resetOnCancelledRequest = true,
}: UseTrackedTransactionOptions): UseTrackedTransactionReturnValue {
  const [chainState, dispatch] = useChainStateReducer()
  const { provider, chainId, activeAddress } = useWeb3ReactPlus()
  const [localTxId, setLocalTxId] = useState<string | undefined>()

  useEffect(() => {
    setLocalTxId(undefined)
  }, [chainId, activeAddress])

  let trackedTx: TrackedTransaction | undefined = chainState[chainId ?? 0]
    ?.addresses?.[activeAddress ?? '']
    ?.transactionsByLocalId
    ?.[localTxId ?? '']

  if (!trackedTx) {
    trackedTx = getDefaultTrackedTransaction()
  }

  const statusAllowsRequest = ![
    TransactionStatus.Requesting,
    TransactionStatus.Pending,
    !allowRequestOnCancelledRequest && TransactionStatus.CancelledRequest,
    !allowRequestOnFailedRequest && TransactionStatus.FailedRequest,
    !allowRequestOnFailed && TransactionStatus.Failed,
    !allowRequestOnConfirmed && TransactionStatus.Confirmed,
  ].includes(trackedTx.status)

  const canRequest = activeAddress
    && contractAddress
    && artifact
    && provider
    && statusAllowsRequest

  function requestTransaction(options?: RequestTransactionOptions): Promise<boolean> {
    const defaultOptions = {
      args: [],
      props: {},
      txOptions: {},
    }
    if (!options) {
      options = defaultOptions
    } else {
      options = {
        ...defaultOptions,
        ...options,
      }
    }
    const { args, props, txOptions } = options as Required<RequestTransactionOptions>
    const functionSignature = getFunctionSignature(artifact, functionName)
    if (!canRequest) {
      console.warn(
        `Attempted to request transaction for ${artifact.name}.${functionSignature} while unavailable. `
        + 'Try disabling transaction requests while canRequest === false for a better user experience.',
      )
      return Promise.resolve(false)
    }

    const newLocalTxId = UUID()
    setLocalTxId(newLocalTxId)

    const contract = new Contract(contractAddress, artifact.abi, provider.getSigner())

    dispatch({
      type: 'TransactionRequested',
      payload: {
        chainId,
        fromAddress: activeAddress,
        localTxId: newLocalTxId,
        toAddress: activeAddress,
        contractAddress,
        contractName: artifact.name,
        functionName,
        functionSignature,
        args,
        props,
      },
    })
    const txPromise: Promise<ContractTransaction> = contract.functions[functionSignature](...args, txOptions)

    return new Promise((resolve) => {
      txPromise
        .catch((e) => {
          // See: https://eips.ethereum.org/EIPS/eip-1193#provider-errors
          if (e.code === 4001) { // User denied request
            if (resetOnCancelledRequest) {
              setLocalTxId(undefined)
              return
            }
            dispatch({
              type: 'TransactionRequestCancelled',
              payload: {
                chainId,
                fromAddress: activeAddress,
                localTxId: newLocalTxId,
              },
            })
            return
          }
          dispatch({
            type: 'TransactionRequestFailed',
            payload: {
              chainId,
              fromAddress: activeAddress,
              localTxId: newLocalTxId,
              error: e,
            },
          })
        })
        .then((tx) => {
          if (!tx) return
          dispatch({
            type: 'TransactionSubmitted',
            payload: {
              chainId,
              fromAddress: activeAddress,
              localTxId: newLocalTxId,
              tx,
            },
          })
          return tx.wait(waitConfirmations)
        })
        .catch((e) => {
          dispatch({
            type: 'TransactionFailed',
            payload: {
              chainId,
              fromAddress: activeAddress,
              localTxId: newLocalTxId,
              error: e,
            },
          })
        })
        .then((txReceipt) => {
          if (!txReceipt) return false
          dispatch({
            type: 'TransactionConfirmed',
            payload: {
              chainId,
              fromAddress: activeAddress,
              localTxId: newLocalTxId,
              txReceipt,
            },
          })
          return true
        })
        .then(resolve)
    })
  }

  function reset() {
    setLocalTxId(undefined)
  }

  return [(trackedTx as TrackedTransaction), requestTransaction, canRequest, reset]
}
