// imports
import styled, { css } from 'styled-components'
import { trimDecimalStringToPrecision } from '../../../utils/trimDecimalStringToPrecision'
import { AppStateActionName, useAppStateReducer } from '../../../AppState'
import { useWeb3ReactPlus } from '../../../Web3ReactPlus/Web3ReactPlusProvider'
import { useTokenIcons } from '../../../hooks/useTokenIcons'
import { useIsCardOpen } from '../../../hooks/useIsCardOpen'

function FeaturedPool({ poolInfo }) {
  const {
    id: poolId,
    pairName,
    emoji,
    platform,
    featuredText,
    apr,
    tvlUsd,
  } = poolInfo
  const [_, dispatch] = useAppStateReducer()
  const { chainId } = useWeb3ReactPlus()
  const isCardOpen = useIsCardOpen(poolId)

  const [token1Svg, token2Svg] = useTokenIcons(poolInfo)

  const featuredTextToTagStyle = (status) => {
    switch (status) {
      case 'New':
        return { green: true }
      case 'Ending':
        return { red: true }
      default:
        return {}
    }
  }

  function handleOnClick() {
    // Scroll immediately if card is already open
    if (isCardOpen) {
      dispatchEvent(new Event(`scrollToPool${poolInfo.id}`))
      return
    }

    dispatch({
      type: AppStateActionName.TogglePoolCard,
      payload: { chainId, poolId },
    })
    // Need to set timeout on scroll because divs are moving around during animation
    setTimeout(() => {
      dispatchEvent(new Event(`scrollToPool${poolInfo.id}`))
    }, 150)
  }

  return (
    <Card>
      <TopGroup>
        <Textdiv>
          <Icon width="31px" height="31px" src={token1Svg} />
          {token2Svg
            ? <Icon overlap width="31px" height="31px" src={token2Svg} />
            : null}
          <MainGroup>
            <MainText>{pairName}</MainText>
            <Platform>
              {emoji}
              {' '}
              {platform}
            </Platform>
          </MainGroup>
        </Textdiv>
        <Tag {...featuredTextToTagStyle(featuredText)}>{featuredText}</Tag>
      </TopGroup>
      <MiddleGroup>
        <TitlesGroups>
          <TitleGroup>
            <PreTitle>APR</PreTitle>
            <Title>
              {/** TODO: revert 0 to apr */}
              {trimDecimalStringToPrecision(0, 2, 5)}
              %
            </Title>
          </TitleGroup>
          <TitleGroup>
            <PreTitle>TVL</PreTitle>
            <Title>{`$${trimDecimalStringToPrecision(tvlUsd, 0)}`}</Title>
          </TitleGroup>
        </TitlesGroups>
      </MiddleGroup>
      <BottomGroup>
        <Button onClick={handleOnClick}>Deposit</Button>
      </BottomGroup>
    </Card>
  )
}

export default FeaturedPool

// styles

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  border-radius: 8px;
  background-color: white;
  padding: 15px 18px 20px;
  :not(:last-child) {
    margin-right: 30px;
  };
`

// TopGroup

const TopGroup = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 33px;
  justify-content: space-between;
`

const Icon = styled.img`
  margin-left: ${
  (props) => (props.overlap ? '-5px' : '0')
};
`

const Tag = styled.div`
  width: 81px;
  height: 35px;
  background-color: #f0f7fe;
  font-size: 14px;
  padding: 9px;
  text-align: center;
  color: #82a7ee;
  font-weight: 600;
  border-radius: 10px;
  ${(props) => props.green
    && css`
      background-color: #E8F9F1;
      color: #32C387;
    `}
  ${(props) => props.red
    && css`
      background-color: #FEF0F0;
      color: #C82E2E;
    `};
  @media (max-width: 1200px) {
    display: none;
  }
`

// MainText Group

const Textdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const MainText = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #0f1621;
  // -webkit-text-stroke-width: 0.2px;
  margin: 0 8px 0 0;
`

// MiddleGroup

const MiddleGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

const MainGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-left: 6px;
`

const TitlesGroups = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`

const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : '20%')};
  margin-left: ${(props) => (props.mr ? '10px' : '0px')};
  // ${({ fullwidth }) => (fullwidth ? '' : 'flex: 1;')}
  flex: 1 auto;
  
  @media (max-width: 1200px) {
    width: 100%;
    flex: unset;
    margin-bottom: 10px;
  }
`

const PreTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #545463;
  text-align: left;
  // -webkit-text-stroke-width: 0.2px;
`

const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: #0f1621;
  // -webkit-text-stroke-width: 0.2px;
`

const Platform = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #545463;
  line-height: 1;
`

// Button

const BottomGroup = styled.div`
  display: flex;
  flex-direction: row;
`

const Button = styled.button`
background-color: #0F1621;
width: 100%;
height: 32px;   
border:none;
border-radius: 50px;
cursor: pointer;
color: white;
font-size: 14px;
font-weight: 600;
&:hover {
  opacity: 0.8;
}
`
