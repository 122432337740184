import { useEffect, useState } from 'react'
import { formatUnits } from 'ethers/lib.esm/utils'
import { usePendingSfi } from './usePendingSfi'
import { bn } from '../utils/bn'
import { trimDecimalStringToPrecision } from '../utils/trimDecimalStringToPrecision'
import { useAmountStaked } from './useAmountStaked'
import { useBlockNumber } from '../Web3ReactPlus/hooks/useBlockNumber'
import { useSfiPrice } from './useSfiPrice'
import { useSaffronStakingProtocolInfo } from './useSaffronStakingProtocolInfo'

export function usePendingUsd(poolId: number) {
  const [pendingUsd, setPendingUsd] = useState<null | string>(null)

  const blockNumber = useBlockNumber()
  const sfiPrice = useSfiPrice()
  const [pendingSfi, pendingSfiIsUpdating] = usePendingSfi(poolId)
  const [amountStaked, amountStakedIsUpdating] = useAmountStaked(poolId)
  const { rewardCutoffBlockNumber, sfiPerBlockWei } = useSaffronStakingProtocolInfo()

  const isEmittingRewards = blockNumber <= rewardCutoffBlockNumber && parseFloat(sfiPerBlockWei) > 0

  useEffect(() => {
    if (!pendingSfi || pendingSfi.toString() === '0') {
      if (!amountStaked || amountStaked.toString() === '0') {
        setPendingUsd(null); return
      }
      setPendingUsd(isEmittingRewards ? '$0' : null); return
    }
    const valueUsd = pendingSfi.mul(bn(sfiPrice, 18)).div(bn(1, 18))
    let pendingRewards = `$${trimDecimalStringToPrecision(formatUnits(valueUsd, 18), 4)}`
    if (pendingRewards === '$0') {
      pendingRewards = '< $0.0001'
    }
    setPendingUsd(pendingRewards)
  }, [pendingSfi])

  return [pendingUsd, pendingSfiIsUpdating || amountStakedIsUpdating]
}
