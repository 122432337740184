export const DEFAULT_CHAIN_ID = process.env.REACT_APP_DEFAULT_CHAIN_ID as string
export const API_HOSTNAME = process.env.REACT_APP_REST_API_HOSTNAME as string

if (!API_HOSTNAME.includes('{network}')) {
  throw Error(
    'REACT_APP_REST_API_HOSTNAME environment variable must include \'{network}\' '
    + 'which will be used to replace the network name.',
  )
}

if (![
  DEFAULT_CHAIN_ID,
  API_HOSTNAME,
].every((env) => !!env)) {
  throw Error('Not all required environment variables set.')
}
