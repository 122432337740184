import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { AppStateActionName, useAppStateReducer } from '../../../AppState'
import { useRawPools } from '../../../hooks/useRawPools'
import { useWeb3ReactPlus } from '../../../Web3ReactPlus/Web3ReactPlusProvider'
import searchIcon from '../../../images/search.svg'
import optionsIcon from '../../../images/options.svg'

function SearchAndFilter() {
  const [_, dispatch] = useAppStateReducer()
  const { chainId } = useWeb3ReactPlus()
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('Default')
  const [sortByDisplay, setSortByDisplay] = useState('Default')

  const rawPools = useRawPools()

  function handleSeachOnChange(e) {
    setSearchQuery(e.target.value)
  }

  function handleSortByChange(e) {
    setSortBy(e.target.value)
    setSortByDisplay(e.target[e.target.selectedIndex].textContent)
    e.target.blur()
  }

  const sortByComparators = {
    Default() {
      return 0
    },
    'APR-asc': function (poolA, poolB) {
      return poolA.apr - poolB.apr
    },
    'APR-desc': function (poolA, poolB) {
      return poolB.apr - poolA.apr
    },
    'TVL-asc': function (poolA, poolB) {
      return poolA.tvlUSD - poolB.tvlUSD
    },
    'TVL-desc': function (poolA, poolB) {
      return poolB.tvlUSD - poolA.tvlUSD
    },
  }

  const isSorted = sortBy !== 'Default'

  useEffect(() => {
    if (searchQuery === '') {
      const pools = [...rawPools]
        .sort(sortByComparators[sortBy] ? sortByComparators[sortBy] : sortByComparators.Default)
      dispatch({
        type: AppStateActionName.DisplayPools,
        payload: { chainId, pools },
      })
      return
    }
    const pools = [...rawPools]
      .sort(sortByComparators[sortBy] ? sortByComparators[sortBy] : sortByComparators.Default)
      .filter(({ searchText }) => {
        const mapped = searchQuery.split(' ')
          .map((term) => searchText.indexOf(term.toLowerCase()) !== -1)
        return mapped.every((isMatch) => isMatch)
      })
    dispatch({
      type: AppStateActionName.DisplayPools,
      payload: { chainId, pools },
    })
  }, [searchQuery, sortBy, rawPools])

  if (!rawPools.length) {
    return null
  }

  return (
    <Container>
      <SearchWrap>
        <Search
          type="search"
          placeholder="Search Pair or Token"
          value={searchQuery}
          onChange={handleSeachOnChange}
        />
        <SearchIcon><img alt="Search" src={searchIcon} /></SearchIcon>
      </SearchWrap>
      <SelectWrap>
        <Select onChange={handleSortByChange} isSorted={isSorted}>
          <Option value="Default">Default</Option>
          <Option value="APR-asc">APR ⭡</Option>
          <Option value="APR-desc">APR ⭣</Option>
          <Option value="TVL-asc">TVL ⭡</Option>
          <Option value="TVL-desc">TVL ⭣</Option>
        </Select>
        <SearchValue>
          Sort by:
          {sortByDisplay}
        </SearchValue>
        <MobileIcon isSorted={isSorted} />
      </SelectWrap>
    </Container>
  )
}

export default SearchAndFilter

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin-bottom: 18px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 17px;
`

const SearchWrap = styled.div`
  position: relative;
  @media(max-width: 800px) {
      flex-basis: 100%;
  }
`

const Search = styled.input`
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  width: 339px;
  height: 42px;
  padding: 8px 14px 8px 32px;
  ::placeholder {
    color: #0f1621;
    font-size: 14px;
    //padding-left: 15px;
  }
  :focus::placeholder {
    color: transparent;
  }
  @media(max-width: 800px) {
    width: 100%;
  }
`

const SearchIcon = styled.div`
  position: absolute;
  left: 11px;
  top: 13px;
`

const SelectWrap = styled.div`
  position: relative;

  @media(max-width: 800px) {
      flex-basis: 54px;
  }
`

const Select = styled.select`
  width: 160px;
  height: 42px;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 6px;
  color: transparent;

  @media(max-width: 800px) {
      width: 54px;
      cursor: pointer;
      border: ${({ isSorted }) => (isSorted ? '2.5px solid rgb(59,143,237)' : 'none')};
  }
`

const Option = styled.option`
  border: none;
  color: black;
`

const SearchValue = styled.span`
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 0;
  padding: 13px;
  line-height: 1;
    
  @media(max-width: 800px) {
      display: none;
  }
`

const MobileIcon = styled.div`
  display: none;
  @media(max-width: 800px) {
      display: block;
  }
  background-color: ${({ isSorted }) => (isSorted ? 'rgb(59, 143, 237)' : '#0F1621')};
  mask-image: url(${optionsIcon});
  mask-repeat: no-repeat;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 16px;
  top: 11px;
  background-repeat: no-repeat;
  pointer-events: none;
`
