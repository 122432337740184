import React, { createContext, ReactNode, useContext } from 'react'
import invariant from 'tiny-invariant'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainStateProvider } from './ChainStateProvider'
import {
  useActivatingConnector, useBlockNumberUpdates, useEagerConnect, useInactiveListener,
} from './hooksPrivate'

function getLibrary(provider: any): Web3Provider {
  return new Web3Provider(provider)
}

interface IWeb3ReactPlusContext<T = any> extends Web3ReactContextInterface<T> {
    provider?: T
    activeAddress?: string | null
    ready: boolean
    triedAutoconnectInjected?: boolean
    activatingConnector?: AbstractConnector
}

const Web3ReactPlusContext = createContext<IWeb3ReactPlusContext<Web3Provider>>({
  active: false,
  ready: false,
  activate: async () => {
    invariant(false, 'No <Web3ReactProviderPlus ... /> found.')
  },
  setError: () => {
    invariant(false, 'No <Web3ReactProviderPlus ... /> found.')
  },
  deactivate: () => {
    invariant(false, 'No <Web3ReactProviderPlus ... /> found.')
  },
})

interface Web3ReactPlusProviderProps {
    children: ReactNode
    defaultChainId: number
}

function _Web3ReactPlusProvider({ defaultChainId, children }: Web3ReactPlusProviderProps) {
  const {
    connector,
    library,
    chainId,
    account,
    activate: _activate,
    deactivate,
    active,
    error,
    setError,
  } = useWeb3React<Web3Provider>()

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useActivatingConnector()

  function activate(connector: any) {
    setActivatingConnector(connector)
    return _activate(connector)
  }

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  // Make sure block number is up-to-date
  useBlockNumberUpdates()

  const ready = !!library && !!chainId && !!account

  const value: IWeb3ReactPlusContext<Web3Provider> = {
    connector,
    library,
    provider: library,
    chainId: chainId ?? defaultChainId,
    account,
    activeAddress: account,
    activate,
    deactivate,
    active,
    ready,
    error,
    setError,
    triedAutoconnectInjected: triedEager,
    activatingConnector,
  }

  return (
    <Web3ReactPlusContext.Provider value={value}>
      { children }
    </Web3ReactPlusContext.Provider>
  )
}

export function Web3ReactPlusProvider(props: Web3ReactPlusProviderProps) {
  const { children } = props
  return (
    <ChainStateProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <_Web3ReactPlusProvider defaultChainId={props.defaultChainId}>
          { children }
        </_Web3ReactPlusProvider>
      </Web3ReactProvider>
    </ChainStateProvider>
  )
}

export function useWeb3ReactPlus() {
  return useContext(Web3ReactPlusContext)
}
