import styled, { css } from 'styled-components'
import { useState } from 'react'
import { AmountForm } from './shared/AmountForm'
import { bn } from '../../../../utils/bn'
import { createContractHelper } from '../../../../utils/createContractHelper'
import SaffronStakingV2 from '../../../../artifacts/contracts/SaffronStakingV2.sol/SaffronStakingV2.json'
import ERC20 from '../../../../artifacts/@openzeppelin/contracts/token/ERC20/ERC20.sol/ERC20.json'
import { useWeb3ReactPlus } from '../../../../Web3ReactPlus/Web3ReactPlusProvider'
import { useIsCardOpen } from '../../../../hooks/useIsCardOpen'
import { useSaffronStakingProtocolInfo } from '../../../../hooks/useSaffronStakingProtocolInfo'
import { useErc20Balance } from '../../../../Web3ReactPlus/hooks/useErc20Balance'
import { useAmountStaked } from '../../../../hooks/useAmountStaked'
import { useEtherscanBaseUrl } from '../../../../hooks/useEtherscanBaseUrl'

const FLEX_ITEM_WIDTH = '520px'
const TABLET_BREAKPOINT = 1023
const MOBILE_BREAKPOINT = 800

const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export function SfiStakingPoolContent({ poolInfo }) {
  const [depositLoadingText, setDepositLoadingText] = useState(null)
  const [depositError, setDepositError] = useState(null)
  const [withdrawLoadingText, setWithdrawLoadingText] = useState(null)
  const [withdrawError, setWithdrawError] = useState(null)

  const { provider, activeAddress } = useWeb3ReactPlus()
  const etherscanBaseUrl = useEtherscanBaseUrl()

  const isCardOpen = useIsCardOpen(poolInfo.id)
  const { saffronStakingAddress } = useSaffronStakingProtocolInfo()
  const [tokenBalance] = useErc20Balance(poolInfo.stakedToken.address, activeAddress, isCardOpen)
  const [amountStaked] = useAmountStaked(poolInfo.id, isCardOpen)

  const saffronStaking = createContractHelper(saffronStakingAddress, SaffronStakingV2.abi, provider)
  const token = createContractHelper(poolInfo.stakedToken.address, ERC20.abi, provider)

  async function handleDeposit({ amount, clearInput }) {
    // Wallet not connected
    if (!saffronStaking.signer) return

    const allowance = await token.reader.allowance(activeAddress, saffronStakingAddress)
    if (amount.gt(allowance)) {
      let approveTx
      try {
        approveTx = await token.signer.approve(saffronStakingAddress, MAX_UINT256)
        clearInput()
        setDepositLoadingText('Approving...')
        await approveTx.wait()
      } catch (e) {
        setDepositLoadingText(null)
        setDepositError(getErrorElement(approveTx, e))
        return
      }
    }
    let tx
    try {
      tx = await saffronStaking.signer.deposit(poolInfo.id, amount)
      clearInput()
      setDepositLoadingText('Depositing...')
      await tx.wait()
    } catch (e) {
      setDepositLoadingText(null)
      setDepositError(getErrorElement(tx, e))
    }
    setDepositLoadingText(null)
  }

  async function handleWithdraw({ amount, clearInput }) {
    // Wallet not connected
    if (!saffronStaking.signer) return

    let tx
    try {
      tx = await saffronStaking.signer.withdraw(poolInfo.id, amount)
      clearInput()
      setWithdrawLoadingText('Withdrawing...')
      await tx.wait()
    } catch (e) {
      setWithdrawLoadingText(null)
      setWithdrawError(getErrorElement(tx, e))
    }
    setWithdrawLoadingText(null)
  }

  // const descriptionWithContent = (medium) => <Description medium={medium}>Earn compounding yield in the SFI Pool.</Description>

  function getErrorElement(tx, error) {
    console.error(error)
    if (!tx || !tx.hash) {
      return null
    }
    return ['Error occurred. ', <a href={`${etherscanBaseUrl}/tx/${tx.hash}`} target="_blank" rel="noreferrer">See details on Etherscan</a>]
  }

  return (
    <Container>

      <DesktopLeftWrapper>
        {/* {descriptionWithContent('desktop')} */}
        <FormWrapper>
          <AmountForm
            titleText="Deposit SFI"
            submitText="Deposit"
            tokenSymbol={poolInfo.stakedToken.displaySymbol}
            tokenPrecision={poolInfo.stakedToken.decimals}
            amountAvailable={tokenBalance || bn(0)}
            onSubmit={handleDeposit}
            loadingText={depositLoadingText}
            errorContent={depositError}
            clearError={() => setDepositError(null)}
            isCardOpen={isCardOpen}
          />
          <AmountForm
            titleText="Withdraw SFI"
            submitText="Withdraw"
            tokenSymbol={poolInfo.stakedToken.displaySymbol}
            tokenPrecision={poolInfo.stakedToken.decimals}
            amountAvailable={amountStaked || bn(0)}
            onSubmit={handleWithdraw}
            loadingText={withdrawLoadingText}
            errorContent={withdrawError}
            clearError={() => setWithdrawError(null)}
            isCardOpen={isCardOpen}
          />
        </FormWrapper>
      </DesktopLeftWrapper>

      <Instructions>
        {/* {descriptionWithContent('mobile')} */}

        Stake SFI to:

        <OrderedList>
          <li>Earn SFI emissions</li>
          <li>Boost your Saffron DAO voting power by 25%</li>
        </OrderedList>

        {/* <Footnote> */}
        {/*    Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur. */}
        {/* </Footnote> */}
        <ContractLinks>
          <ContractLinkGroup>
            <ContractLinkTop>
              Token contract:
            </ContractLinkTop>
            <ContractLinkBottom>
              <a
                className="use-icon"
                href={`${etherscanBaseUrl}/token/${poolInfo.stakedToken.address}`}
                target="_blank"
                rel="noreferrer"
              >
                etherscan.io
              </a>
            </ContractLinkBottom>
          </ContractLinkGroup>
          <ContractLinkGroup>
            <ContractLinkTop>
              Saffron contract:
            </ContractLinkTop>
            <ContractLinkBottom>
              <a
                className="use-icon"
                href={`${etherscanBaseUrl}/address/${saffronStakingAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                etherscan.io
              </a>
            </ContractLinkBottom>
          </ContractLinkGroup>
          <ContractLinkGroup>
            <ContractLinkTop>
              Pool details:
            </ContractLinkTop>
            <ContractLinkBottom>
              <a
                className="use-icon"
                href="https://github.com/saffron-finance"
                target="_blank"
                rel="noreferrer"
              >
                github.com
              </a>
            </ContractLinkBottom>
          </ContractLinkGroup>
        </ContractLinks>
        {/* <a className={'use-icon'} href={'#'} rel={'noreferrer'}>View Contract</a> */}
      </Instructions>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    //height: 270px;
    
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: center;
    }
`

const Description = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;

  @media only screen and (min-width: ${TABLET_BREAKPOINT + 1}px) {
    ${({ medium }) => (medium === 'mobile' ? 'display: none;' : '')}
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ medium }) => (medium === 'desktop' ? 'display: none;' : '')}
  }
`

const DesktopLeftWrapper = styled.div``

const FormWrapper = styled.div`
    display: flex;
    //flex-direction: column;
    //height: 220px;
    //width: 600px;
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      //flex-wrap: unset;
      //flex-direction: column;
      align-items: center;
      justify-content: center;
      column-gap: 60px;

      > div {
        padding: 30px 0 0;
        width: unset;
      }
      gap: 20px;
    }
`

const Instructions = styled.div`
    font-size: 14px;
    font-weight: 500;
    overflow: auto;
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      padding: 0 0 20px
    }
`

const OrderedList = styled.ol`
    font-weight: normal;
    font-size: 14px;
    li {
      line-height: 2;
    }
`

const ContractLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
`

const ContractLinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`

const ContractLinkTop = styled.div`
  color: #545463;
  font-size: 12px;
`

const ContractLinkBottom = styled.div`
  margin: 8px 0;
  font-weight: 700;
`

const Footnote = styled.p`
    font-weight: normal;
    font-size: 12px;
    //width: ${FLEX_ITEM_WIDTH};
    color: #545463;
`
