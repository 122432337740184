import { useWeb3ReactPlus } from '../Web3ReactPlus/Web3ReactPlusProvider'

const chainIdToEtherscanNetwork: { [chainId: number]: string } = {
  4: 'rinkeby',
}

export function useEtherscanBaseUrl() {
  const { chainId } = useWeb3ReactPlus()

  const networkName = chainIdToEtherscanNetwork[chainId || 0]

  if (!networkName) {
    return 'https://etherscan.io'
  }
  return `https://${networkName}.etherscan.io`
}
