import { BigNumber } from '@ethersproject/bignumber'
import { UseContractCallValueReturnValue, useContractCallValue } from './useContractCallValue'
import ERC20 from '../artifacts/ERC20.json'

export function useErc20Balance(
  tokenAddress: string,
  balanceAddress: string,
  autoUpdate = true,
  updateEveryNBlocks = 1,
): UseContractCallValueReturnValue<BigNumber> {
  return useContractCallValue({
    contractAddress: tokenAddress,
    functionName: 'balanceOf',
    artifact: ERC20,
    args: [balanceAddress],
    autoUpdate,
    updateEveryNBlocks,
  })
}
