import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Menu, MenuItem } from '@szhsin/react-menu'
import { Image } from './shared/Image'

import NavIcon from '../../images/navlogo.svg'
import GearIcon from '../../images/gear.svg'
import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/index.css'
import KebabIcon from '../../images/kebab.svg'
import Discord from '../../images/discord.svg'
import Twitter from '../../images/twitter.svg'
import Telegram from '../../images/telegram.svg'
import Youtube from '../../images/youtube.svg'
import Github from '../../images/github.svg'
import Medium from '../../images/medium.svg'
import V1 from '../../images/v1.svg'
import { useWeb3ReactPlus } from '../../Web3ReactPlus/Web3ReactPlusProvider'
import { ConnectWalletModal } from './modals/ConnectWallet'

function Navbar() {
  const [settingsPopup, setSettingsPopup] = useState(false)
  const {
    activate, activeAddress, chainId, provider, deactivate, connector,
  } = useWeb3ReactPlus()

  const [modalOpen, setModalOpen] = useState(false)

  function toggleConnectWalletModal() {
    setModalOpen(!modalOpen)
  }

  let isMetaMask = false
  if (provider) {
    isMetaMask = provider.connection?.url === 'metamask'
  }

  function CommunityMenu({ isMobile }) {
    return (
      <Menu
        menuButton={<IconDivWrapper><IconDiv><Image src={KebabIcon} /></IconDiv></IconDivWrapper>}
        transition
        direction={isMobile ? 'top' : 'bottom'}
        align="end"
        position="anchor"
        arrow
        offsetY={-10}
      >
        <MenuItem target="_blank" rel="noreferrer" href="https://discord.gg/pDXpXKY">
          Discord
          <CommunityIcon src={Discord} />
        </MenuItem>
        <MenuItem target="_blank" rel="noreferrer" href="https://twitter.com/saffronfinance_">
          Twitter
          <CommunityIcon src={Twitter} />
        </MenuItem>
        <MenuItem target="_blank" rel="noreferrer" href="https://t.me/saffronfinance">
          Telegram
          <CommunityIcon src={Telegram} />
        </MenuItem>
        <MenuItem target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCk_ZDXcc9Z56p9HWp7tFArA">
          Youtube
          <CommunityIcon src={Youtube} />
        </MenuItem>
        <MenuItem target="_blank" rel="noreferrer" href="https://github.com/saffron-finance/saffron">
          Github
          <CommunityIcon src={Github} />
        </MenuItem>
        <MenuItem target="_blank" rel="noreferrer" href="https://medium.com/saffron-finance/">
          Medium
          <CommunityIcon src={Medium} />
        </MenuItem>
        <MenuItem target="_blank" rel="noreferrer" href="https://v1.saffron.finance/#community">
          Saffron V1
          <CommunityIcon src={V1} />
        </MenuItem>
      </Menu>
    )
  }

  const logoText = chainId === 4 ? 'rinkeby.saffron.finance' : 'saffron.finance'

  function Address({ address }) {
    return (
      <AddressWrapper>
        <JazziconWrapper isMetaMask={isMetaMask}>
          <Jazzicon diameter={16} seed={jsNumberForAddress(activeAddress)} />
        </JazziconWrapper>
        {address.substr(0, 6)}
        ...
        {address.substr(address.length - 4)}
      </AddressWrapper>
    )
  }

  const MobileNavbar = (
    <FixedNavbar>
      {
      activeAddress
        ? <Address address={activeAddress} />
        : (
          <ConnectButton onClick={toggleConnectWalletModal}>
            Connect Wallet
          </ConnectButton>
        )
    }
      <CommunityMenu isMobile />
    </FixedNavbar>
  )

  return (
    <Container>
      {MobileNavbar}
      <NavbarItems>
        <Items>
          <Image src={NavIcon} />
          <SaffronText>{logoText}</SaffronText>
          {/* <Navigation> */}
          {/*  <NavItem active> */}
          {/*    Dashboard */}
          {/*  </NavItem> */}
          {/*  <NavItem> */}
          {/*    Portfolio */}
          {/*  </NavItem> */}
          {/*  <NavItem> */}
          {/*    Analytics */}
          {/*  </NavItem> */}
          {/*  <NavItem> */}
          {/*    Vote */}
          {/*  </NavItem> */}
          {/* </Navigation> */}
        </Items>
        <Items desktopOnly>
          {
            activeAddress
              ? <Address address={activeAddress} />
              : (
                <ConnectButton onClick={toggleConnectWalletModal}>
                  Connect Wallet
                </ConnectButton>
              )
          }
          {/* <IconDiv> */}
          {/*  <Image onClick={() => setSettingsPopup(true)} src={GearIcon}  /> */}
          {/* </IconDiv> */}
          <CommunityMenu />

        </Items>
      </NavbarItems>
      <ConnectWalletModal isOpen={modalOpen} onRequestClose={toggleConnectWalletModal} />
    </Container>
  )
}

export default Navbar

// styles

const SaffronText = styled.div`
  cursor: default;
  user-select: none;
  margin-left: 10px;
  font-size: 22px;
  color: #494955;
  font-weight: normal;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const NavbarItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
`

const Items = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  font-weight: 600;
  ${({ desktopOnly }) => {
    if (desktopOnly) {
      return css`
        @media (max-width: 800px) {
          display: none;
        }
      `
    }
  }}
`

const Navigation = styled.div`
  width: 381px;
  margin-left: 10px;
  display: flex;
  justify-content: space-around;
  background-color: #F7F8FE;
  border-radius: 20px;
`

const NavItem = styled.div`
  padding-top: 2px;
  padding-bottom: 4px;
  color: #545463;
  cursor: pointer;
  font-weight: 500;
  ${(props) => props.active
    && css`
      background-color: #ffffff;
      padding-left: 10px;
      margin-left: -15px;
      padding-right: 10px;
      margin-right: -15px;
      color: #0F1621;
      border-radius: 8px;
    `
}
`

const IconDiv = styled.div`
  margin: 5px 5px 20px;
  margin-top: 20px;
  cursor: pointer;
  display:flex;
  align-items: center;
`

const IconDivWrapper = styled.div`
  cursor: pointer;
`

const CommunityIcon = styled.img`
  position: absolute;
  right: 18px;
`

export const ConnectButton = styled.button`
  background-color: #C44536;
  width: 162px;
  height: 32px;
  margin-right: 14px;
  border:none;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
  }
`

export const AddressWrapper = styled.div`
  cursor: default;
  user-select: none;
  height: 32px;
  border-radius: 10px;
  background-color: white;
  padding: 8px 13px;
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-right: 14px;

  //@media (max-width: 800px) {
  //  display: none;
  //}
`

export const JazziconWrapper = styled.div`
  margin-right: 8px;
  ${({ isMetaMask }) => {
    if (!isMetaMask) {
      return css`
        .paper {
          background-color: rgb(1, 142, 114) !important;
        }
        svg {
          opacity: 0;
        }
      `
    }
  }}
  
`

const FixedNavbar = styled.div`
  position: fixed;
  display: none;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: #EEF0FD;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 100;

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    
    .szh-menu-container { // take element out of flex flow
      position: absolute;
    }
  }
`
