import { useAppStateReducer } from '../AppState'
import { useWeb3ReactPlus } from '../Web3ReactPlus/Web3ReactPlusProvider'

// Needed so reference doesn't keep changing, leading to infinite loop
const noPools: any = []

export function useRawPools() {
  const { chainId } = useWeb3ReactPlus()
  const [appState] = useAppStateReducer()
  if (!chainId) return noPools
  const rawPools = appState[chainId]?.api?.pools?.data?.stakingPools

  if (!rawPools) return noPools
  return rawPools
}
