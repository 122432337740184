import styled from 'styled-components'
import { StakingPoolContent } from './pool-content-types/StakingPoolContent'
import { SfiStakingPoolContent } from './pool-content-types/SfiStakingPoolContent'

export function PoolCardFullContent(props) {
  function getPoolContent() {
    const propsWithoutRef = { ...props, innerRef: undefined }
    switch (props.poolInfo.type) {
      // REGISTER NEW POOL CONTENT TYPES HERE
      //
      // Template:
      // case 'NAME_OF_TYPE': return <MY_CONTENT_COMPONENT {...props} />

      case 'sfi-staking': return <SfiStakingPoolContent {...propsWithoutRef} />
      case 'staking': return <StakingPoolContent {...propsWithoutRef} />

        /// ///////////////////////////////////////////////

      default: throw Error(`Pool type '${props.poolInfo.type}' not supported.`)
    }
  }

  return (
    <PoolContentWrapper ref={props.innerRef}>
      {getPoolContent()}
    </PoolContentWrapper>
  )
}

const PoolContentWrapper = styled.div`
  padding: 22px 30px;
  border-top: 1px #eef0fd solid;
`
