import Modal from 'react-modal'
import styled from 'styled-components'
import { useState } from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { useWeb3ReactPlus } from '../../../Web3ReactPlus/Web3ReactPlusProvider'
import closeIcon from '../../../images/close-x.svg'
import metamaskIcon from '../../../images/metamask.svg'
import coinbaseWalletIcon from '../../../images/coinbase-wallet.svg'
import walletConnectIcon from '../../../images/wallet-connect.svg'
import fortmaticIcon from '../../../images/fortmatic.svg'
import {
  fortmatic, injected, walletconnect, walletlink,
} from '../../../Web3ReactPlus/connectors'

interface ConnectWalletProps {
  isOpen: boolean
  onRequestClose: (...args: any[]) => any
}

interface SupportedWallet {
  name: string
  iconSvg: string
  connector: AbstractConnector
}

const TRANSITION_MS = 200

const supportedWallets: SupportedWallet[] = [
  {
    name: 'MetaMask',
    iconSvg: metamaskIcon,
    connector: injected,
  },
  {
    name: 'Coinbase Wallet',
    iconSvg: coinbaseWalletIcon,
    connector: walletlink,
  },
  {
    name: 'WalletConnect',
    iconSvg: walletConnectIcon,
    connector: walletconnect,
  },
  {
    name: 'Fortmatic',
    iconSvg: fortmaticIcon,
    connector: fortmatic,
  },
]

export function ConnectWalletModal(props: ConnectWalletProps) {
  const { isOpen, onRequestClose } = props
  const { activate, activeAddress } = useWeb3ReactPlus()

  const [triedActivation, setTriedActivation] = useState<boolean>(false)

  if (triedActivation) {
    if (activeAddress) {
      props.onRequestClose()
    }
    setTriedActivation(false)
  }

  async function tryActivate(connector: AbstractConnector, closeModal: () => void) {
    if (connector instanceof WalletConnectConnector && (connector.walletConnectProvider as any)?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }

    try {
      await activate(connector)
      setTriedActivation(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal
      closeTimeoutMS={TRANSITION_MS + 17}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      contentElement={(props, children) => <ModalElement {...props}>{children}</ModalElement>}
      overlayElement={(props, contentElement) => <OverlayElement {...props}>{contentElement}</OverlayElement>}
    >
      <Title>Connect to a Wallet:</Title>
      <Close onClick={onRequestClose}><img alt="Close" src={closeIcon} /></Close>
      <WalletItems>
        {supportedWallets.map(({ name, iconSvg, connector }, i: number) => (
          <WalletItem key={`wallet-${i}`} onClick={() => tryActivate(connector, onRequestClose)}>
            <p>{name}</p>
            <div><img alt={`Connect using ${name}`} src={iconSvg} /></div>
          </WalletItem>
        ))}
      </WalletItems>
    </Modal>
  )
}

const ModalElement = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 380px;
  background: #fff;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;

  @media (max-width: 800px) {
    width: 95%;
    max-width: 380px;
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 26px;
`

const Close = styled.div`
  position: fixed;
  right: 1px;
  top: 2px;
  padding: 10px 15px;
  cursor: pointer;
`

const WalletItems = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 11px;
`

const OverlayElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EEF0FDAF;
  z-index: 10;
  backdrop-filter: blur(2px);
  transition: opacity ${TRANSITION_MS}ms ease-out;

  &.ReactModal__Overlay {
    opacity: 0;
  }
  
  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`

const WalletItem = styled.div`
  height: 60px;
  border: 3px solid #E6E8F3;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  
  transition: border ease 0.15s;
  
  &:hover {
    border: 3px solid #c8cde4;
  }


  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  align-items: center;
  
  div {
    width: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`
