import { useRawPools } from './useRawPools'

export function useSfiPrice() {
  const pools = useRawPools()
  const sfiPool = pools.find((pool: any) => pool.stakedToken?.symbol === 'SFI')
  if (!sfiPool) {
    return 0
  }
  return sfiPool.tokenPriceUsd
}
