import { useAppStateReducer } from '../AppState'
import { useWeb3ReactPlus } from '../Web3ReactPlus/Web3ReactPlusProvider'
import { getTokenIcon } from '../utils/getTokenIcon'

const noIcons: any = []

export function useTokenIcons(poolInfo: any) {
  const { chainId } = useWeb3ReactPlus()

  const { stakedToken } = poolInfo

  let symbol1 = 'SFI-Blue'
  let symbol2
  if (stakedToken.underlyingToken0) {
    ([symbol1, symbol2] = poolInfo.pairName.split('/'))
  }

  if (!chainId) return noIcons
  const iconSvg1 = getTokenIcon(symbol1)
  if (!symbol2) {
    return [iconSvg1]
  }
  const iconSvg2 = getTokenIcon(symbol2)
  return [iconSvg1, iconSvg2]
}
