import React, {
  createContext, ReactNode, Reducer, useContext, useReducer,
} from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { deepmergeCustom } from 'deepmerge-ts'
import { ContractReceipt, ContractTransaction } from 'ethers'
import { TrackedTransaction, TransactionStatus } from './hooks/useTrackedTransaction'

interface ChainState {
    [chainId: number]: {
        blockNumber: number
        addresses: {
            [address: string]: {
                balance?: BalanceState
                callValues?: {
                    [functionSignature: string]: {
                        [argsHash: string]: CallValueState
                    }
                }
                transactionsByLocalId?: {
                    [localTxId: string]: TrackedTransaction
                }
            }
        }
    }
}

export interface CallValueState<T = any> {
    value?: T
    isUpdating?: boolean
    error?: Error
    mostRecentError?: Error
    lastUpdatedBlock?: number
}

export type BalanceState = CallValueState<BigNumber>

enum ChainStateActionName {
    BlockNumberUpdated = 'BlockNumberUpdated',
    CallValueUpdating = 'CallValueUpdating',
    CallValueUpdated = 'CallValueUpdated',
    CallValueUpdateFailed = 'CallValueUpdateFailed',
    BalanceUpdating = 'BalanceUpdating',
    BalanceUpdated = 'BalanceUpdated',
    BalanceUpdateFailed = 'BalanceUpdateFailed',
    TransactionRequested = 'TransactionRequested',
    TransactionRequestCancelled = 'TransactionRequestCancelled',
    TransactionRequestFailed = 'TransactionRequestFailed',
    TransactionSubmitted = 'TransactionSubmitted',
    TransactionConfirmed = 'TransactionConfirmed',
    TransactionFailed = 'TransactionFailed',
}

interface Action {
    type: string
    payload: unknown
}

interface BlockNumberUpdatedAction extends Action {
    type: ChainStateActionName.BlockNumberUpdated
    payload: {
        chainId: number
        blockNumber: number
    }
}

interface CallValueUpdatingAction extends Action {
    type: ChainStateActionName.CallValueUpdating
    payload: {
        chainId: number
        address: string
        functionSignature: string
        argsHash: string
    }
}

interface CallValueUpdatedAction extends Action {
    type: ChainStateActionName.CallValueUpdated
    payload: {
        chainId: number
        address: string
        functionSignature: string
        argsHash: string
        value: any
        blockNumber: number
    }
}

interface CallValueUpdateFailedAction extends Action {
    type: ChainStateActionName.CallValueUpdateFailed
    payload: {
        chainId: number
        address: string
        functionSignature: string
        argsHash: string
        error: Error
        blockNumber: number
    }
}

interface BalanceUpdatingAction extends Action {
    type: ChainStateActionName.BalanceUpdating
    payload: {
        chainId: number
        address: string
    }
}

interface BalanceUpdatedAction extends Action {
    type: ChainStateActionName.BalanceUpdated
    payload: {
        chainId: number
        address: string
        value: BigNumber
        blockNumber: number
    }
}

interface BalanceUpdateFailedAction extends Action {
    type: ChainStateActionName.BalanceUpdateFailed
    payload: {
        chainId: number
        address: string
        error: Error
        blockNumber: number
    }
}

interface TransactionRequestedAction extends Action {
    type: ChainStateActionName.TransactionRequested
    payload: {
        chainId: number
        fromAddress: string
        localTxId: string
        toAddress: string
        contractAddress: string
        contractName: string
        functionName: string
        functionSignature: string
        args: any[]
        props: any
    }
}

interface TransactionRequestFailedAction extends Action {
    type: ChainStateActionName.TransactionRequestFailed
    payload: {
        chainId: number
        fromAddress: string
        localTxId: string
        error: Error
    }
}

interface TransactionRequestCancelledAction extends Action {
    type: ChainStateActionName.TransactionRequestCancelled
    payload: {
        chainId: number
        fromAddress: string
        localTxId: string
    }
}

interface TransactionSubmittedAction extends Action {
    type: ChainStateActionName.TransactionSubmitted
    payload: {
        chainId: number
        fromAddress: string
        localTxId: string
        tx: ContractTransaction
    }
}

interface TransactionConfirmedAction extends Action {
    type: ChainStateActionName.TransactionConfirmed
    payload: {
        chainId: number
        fromAddress: string
        localTxId: string
        txReceipt: ContractReceipt
    }
}

interface TransactionFailedAction extends Action {
    type: ChainStateActionName.TransactionFailed
    payload: {
        chainId: number
        fromAddress: string
        localTxId: string
        error: Error
    }
}

type ChainStateAction =
    | BlockNumberUpdatedAction
    | CallValueUpdatingAction
    | CallValueUpdatedAction
    | CallValueUpdateFailedAction
    | BalanceUpdatingAction
    | BalanceUpdatedAction
    | BalanceUpdateFailedAction
    | TransactionRequestedAction
    | TransactionRequestFailedAction
    | TransactionRequestCancelledAction
    | TransactionSubmittedAction
    | TransactionConfirmedAction
    | TransactionFailedAction

// Always overwrite arrays
const deepMerge = deepmergeCustom({ mergeArrays: false })

function chainStateReducer(state: ChainState, action: ChainStateAction): ChainState {
  switch (action.type) {
    case ChainStateActionName.BlockNumberUpdated:
      return blockNumberUpdated(state, action.payload)
    case ChainStateActionName.CallValueUpdating:
      return callValueUpdating(state, action.payload)
    case ChainStateActionName.CallValueUpdated:
      return callValueUpdated(state, action.payload)
    case ChainStateActionName.CallValueUpdateFailed:
      return callValueUpdateFailed(state, action.payload)
    case ChainStateActionName.BalanceUpdating:
      return balanceUpdating(state, action.payload)
    case ChainStateActionName.BalanceUpdated:
      return balanceUpdated(state, action.payload)
    case ChainStateActionName.BalanceUpdateFailed:
      return balanceUpdateFailed(state, action.payload)
    case ChainStateActionName.TransactionRequested:
      return transactionRequested(state, action.payload)
    case ChainStateActionName.TransactionRequestFailed:
      return transactionRequestFailed(state, action.payload)
    case ChainStateActionName.TransactionRequestCancelled:
      return transactionRequestCancelled(state, action.payload)
    case ChainStateActionName.TransactionSubmitted:
      return transactionSubmitted(state, action.payload)
    case ChainStateActionName.TransactionConfirmed:
      return transactionConfirmed(state, action.payload)
    case ChainStateActionName.TransactionFailed:
      return transactionFailed(state, action.payload)
    default:
      // @ts-ignore
      throw new Error(`Action type '${action.type} not supported.`)
  }
}

function blockNumberUpdated(state: ChainState, payload: BlockNumberUpdatedAction['payload']) {
  const { chainId, blockNumber } = payload
  return deepMerge(state, {
    [chainId]: {
      blockNumber,
    },
  }) as ChainState
}

function callValueUpdating(state: ChainState, payload: CallValueUpdatingAction['payload']) {
  const {
    chainId, address, functionSignature, argsHash,
  } = payload
  return deepMerge(state, {
    [chainId]: {
      addresses: {
        [address]: {
          callValues: {
            [functionSignature]: {
              [argsHash]: {
                isUpdating: true,
              },
            },
          },
        },
      },
    },
  }) as ChainState
}

function callValueUpdated(state: ChainState, payload: CallValueUpdatedAction['payload']) {
  const {
    chainId, address, functionSignature, argsHash, value, blockNumber,
  } = payload
  return deepMerge(state, {
    [chainId]: {
      addresses: {
        [address]: {
          callValues: {
            [functionSignature]: {
              [argsHash]: {
                value,
                isUpdating: false,
                error: undefined,
                lastUpdatedBlock: blockNumber,
              },
            },
          },
        },
      },
    },
  }) as ChainState
}

function callValueUpdateFailed(state: ChainState, payload: CallValueUpdateFailedAction['payload']) {
  const {
    chainId, address, functionSignature, argsHash, error,
  } = payload
  return deepMerge(state, {
    [chainId]: {
      addresses: {
        [address]: {
          callValues: {
            [functionSignature]: {
              [argsHash]: {
                isUpdating: false,
                error,
                mostRecentError: error,
              },
            },
          },
        },
      },
    },
  }) as ChainState
}

function balanceUpdating(state: ChainState, payload: BalanceUpdatingAction['payload']) {
  const { chainId, address } = payload
  return deepMerge(state, {
    [chainId]: {
      addresses: {
        [address]: {
          balance: {
            isUpdating: true,
          },
        },
      },
    },
  }) as ChainState
}

function balanceUpdated(state: ChainState, payload: BalanceUpdatedAction['payload']) {
  const {
    chainId, address, value, blockNumber,
  } = payload
  return deepMerge(state, {
    [chainId]: {
      addresses: {
        [address]: {
          balance: {
            value,
            isUpdating: false,
            error: undefined,
            lastUpdatedBlock: blockNumber,
          },
        },
      },
    },
  }) as ChainState
}

function balanceUpdateFailed(state: ChainState, payload: BalanceUpdateFailedAction['payload']) {
  const {
    chainId, address, error, blockNumber,
  } = payload
  return deepMerge(state, {
    [chainId]: {
      addresses: {
        [address]: {
          balance: {
            isUpdating: false,
            error,
            mostRecentError: error,
            lastUpdatedBlock: blockNumber,
          },
        },
      },
    },
  }) as ChainState
}

function transactionRequested(state: ChainState, payload: TransactionRequestedAction['payload']) {
  return deepMerge(state, {
    [payload.chainId]: {
      addresses: {
        [payload.fromAddress]: {
          transactionsByLocalId: {
            [payload.localTxId]: {
              status: TransactionStatus.Requesting,
              fromAddress: payload.fromAddress,
              localTxId: payload.localTxId,
              contractAddress: payload.contractAddress,
              contractName: payload.contractName,
              functionName: payload.functionName,
              functionSignature: payload.functionSignature,
              args: payload.args,
              props: payload.props,
            },
          },
        },
      },
    },
  }) as ChainState
}

function transactionRequestFailed(state: ChainState, payload: TransactionRequestFailedAction['payload']) {
  return deepMerge(state, {
    [payload.chainId]: {
      addresses: {
        [payload.fromAddress]: {
          transactionsByLocalId: {
            [payload.localTxId]: {
              status: TransactionStatus.FailedRequest,
              fromAddress: payload.fromAddress,
              localTxId: payload.localTxId,
              error: payload.error,
            },
          },
        },
      },
    },
  }) as ChainState
}

function transactionRequestCancelled(state: ChainState, payload: TransactionRequestCancelledAction['payload']) {
  return deepMerge(state, {
    [payload.chainId]: {
      addresses: {
        [payload.fromAddress]: {
          transactionsByLocalId: {
            [payload.localTxId]: {
              status: TransactionStatus.CancelledRequest,
              fromAddress: payload.fromAddress,
              localTxId: payload.localTxId,
            },
          },
        },
      },
    },
  }) as ChainState
}

function transactionSubmitted(state: ChainState, payload: TransactionSubmittedAction['payload']) {
  return deepMerge(state, {
    [payload.chainId]: {
      addresses: {
        [payload.fromAddress]: {
          transactionsByLocalId: {
            [payload.localTxId]: {
              status: TransactionStatus.Pending,
              fromAddress: payload.fromAddress,
              localTxId: payload.localTxId,
              timeSubmitted: new Date(),
              tx: payload.tx,
            },
          },
        },
      },
    },
  }) as ChainState
}

function transactionConfirmed(state: ChainState, payload: TransactionConfirmedAction['payload']) {
  return deepMerge(state, {
    [payload.chainId]: {
      addresses: {
        [payload.fromAddress]: {
          transactionsByLocalId: {
            [payload.localTxId]: {
              status: TransactionStatus.Confirmed,
              fromAddress: payload.fromAddress,
              localTxId: payload.localTxId,
              txReceipt: payload.txReceipt,
            },
          },
        },
      },
    },
  }) as ChainState
}

function transactionFailed(state: ChainState, payload: TransactionFailedAction['payload']) {
  return deepMerge(state, {
    [payload.chainId]: {
      addresses: {
        [payload.fromAddress]: {
          transactionsByLocalId: {
            [payload.localTxId]: {
              status: TransactionStatus.Failed,
              fromAddress: payload.fromAddress,
              localTxId: payload.localTxId,
              error: payload.error,
            },
          },
        },
      },
    },
  }) as ChainState
}

export const ChainStateContext = createContext([{} as any, (action: any) => {}])

interface ChainStateProviderProps {
    children: ReactNode
}

export function ChainStateProvider({ children }: ChainStateProviderProps) {
  const [store, dispatch] = useReducer<Reducer<ChainState, ChainStateAction>>(chainStateReducer, {})

  return (
    <ChainStateContext.Provider value={[store, dispatch]}>
      {children}
    </ChainStateContext.Provider>
  )
}

export function useChainStateReducer() {
  return useContext(ChainStateContext) as [ChainState, React.Dispatch<any>]
}
